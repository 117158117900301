var video = document.querySelector('.video-container');
var popup = document.querySelector('.video-popup');

video.addEventListener('click', function(evt) {
  video.classList.toggle('video-container__open')
  popup.classList.toggle('video-popup__open')
});

document.querySelector('video').addEventListener('timeupdate', function(e) {
  if(e.target.currentTime >= 23.5) {
    e.target.currentTime = 0.0;
}
}, false);