var dropCookie = true;                      // false disables the Cookie, allowing you to style the banner
var cookieDuration = 14;                    // Number of days before the cookie expires, and the banner reappears
var cookieName = 'graftonplaceCookie';        // Name of our cookie
var cookieValue = 'on';                     // Value of cookie

function createDiv(){
  var bodytag = document.getElementsByTagName('body')[0];
  var div = document.createElement('div');
  div.setAttribute('id','cookie-law');
  div.innerHTML = `
    <div>
      <p>We use cookies to store information to provide the best possible experience. By continuing to browse you are accepting our <a id="privacy-popup" href="/legal">Privacy &amp; Cookie Policy</a></p>
      <a id="close-cookies" onclick="document.getElementById('cookie-law').style.display = 'none';"><i class="ion-icon ion-md-close"></i></a>
    </div>
  `;

  bodytag.appendChild(div); // Adds the Cookie Law Banner just before the closing </body> tag
  createCookie(cookieName, cookieValue, cookieDuration);
}

function createCookie(name,value,days) {
  if (days) {
      var date = new Date();
      date.setTime(date.getTime()+(days*24*60*60*1000));
      var expires = "; expires="+date.toGMTString();
  }
  else var expires = "";
  if(dropCookie) {
      document.cookie = name+"="+value+expires+"; path=/";
  }
}

function checkCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

function eraseCookie(name) {
  createCookie(name,"",-1);
}

// First check to determine to create cookie banner
if(checkCookie(cookieName) != cookieValue){
  createDiv();
}
