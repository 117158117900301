// Terms Page
export function render() {

  var termsHTML = `
  <div class="legal-page">
      <div class="back"><a class="back__btn" href="/"><i class="ion-ios-arrow-round-back"></i></a></div>
      <a href="/">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="278" height="191" viewBox="0 0 278 191">
            <defs>
                <path id="a" d="M.16.508h237.134v55.924H.159z"/>
            </defs>
            <g fill="none" fill-rule="evenodd">
                <path fill="#001faf" d="M251.742 69.77v41.111L217.916 69.77h-8.395v53.391h7.709V82.736l33.9 40.425h8.397V69.77h-7.785zm-78.82 47.214c-11.373 0-19.692-8.696-19.692-20.519 0-11.823 8.319-20.517 19.692-20.517s19.692 8.694 19.692 20.517c0 11.823-8.32 20.519-19.692 20.519zm0-48.435c-15.798 0-27.554 11.898-27.554 27.916s11.756 27.917 27.554 27.917c15.8 0 27.553-11.899 27.553-27.917S188.722 68.55 172.922 68.55zm-51.66 8.543h22.128V69.77H91.423v7.322h22.13v46.069h7.709v-46.07z"/>
                <g transform="translate(39.962 133.7)">
                    <mask id="b" fill="#fff">
                        <use xlink:href="#a"/>
                    </mask>
                    <path fill="#001faf" d="M237.294 9.064V1.73h-38.372v53.478h38.372v-7.334h-30.65v-15.89h28.473v-7.335h-28.473V9.064h30.65zm-69.197 39.88c-10.628 0-19.419-7.716-19.419-20.551 0-12.682 8.562-20.475 19.341-20.475 6.575 0 12.384 2.904 15.749 8.404l6.345-4.354C185.45 4.48 177.651.508 168.401.508c-15.595 0-27.597 11.383-27.597 28.344 0 15.585 10.167 27.58 26.374 27.58 11.238 0 20.03-5.654 24.54-16.044l-6.65-2.827c-2.982 7.334-9.405 11.383-16.971 11.383zm-63.213-14.363l8.639-22.613 8.715 22.613h-17.354zm25.228 20.627h8.333L117.27 1.73h-7.416L88.677 55.208h8.332l5.122-13.369h22.783l5.198 13.37zm-75.254-7.334V1.73h-7.723v53.478h36.939v-7.334H54.858zM7.88 26.406V8.836h15.532c5.886 0 8.868 4.201 8.868 8.403 0 4.508-3.134 9.167-9.557 9.167H7.88zM23.795 1.73H.159v53.478h7.72V33.512H22.8c11.239 0 17.355-8.327 17.355-16.426 0-7.792-5.199-15.356-16.36-15.356z" mask="url(#b)"/>
                </g>
                <path fill="#001faf" d="M203.906 32.638v-7.346h-27.834V9.297h30.012V1.95h-37.746v53.57h7.734V32.638h27.834zm-78.837 2.219l9.744-22.652 9.82 22.652h-19.564zm27.45 20.663h8.349L138.565 1.95h-7.429l-22.301 53.57h8.347l5.131-13.393h25l5.207 13.393zM66.724 9.068h15.558c6.05 0 9.42 3.903 9.42 8.265 0 4.515-3.446 8.495-9.496 8.495H66.723V9.068zm21.761 21.81c7.121-2.22 11.026-7.959 11.026-14.081 0-7.577-5.206-14.847-16.616-14.847H58.988v53.57h7.735V32.715h13.95L94.151 55.52h9.112L88.484 30.88zM25.883 34.17h14.396v11.785c-3.139 2.296-8.346 3.597-12.864 3.597-10.261 0-19.527-7.193-19.527-20.74 0-12.856 8.576-20.662 19.374-20.662 6.585 0 12.405 2.909 15.774 8.419l6.28-4.363C44.567 4.629 36.986.725 27.645.725 12.022.726 0 12.13 0 29.119c0 15.61 10.185 27.626 26.495 27.626 8.347 0 16.158-2.984 21.52-7.882V26.974H25.882v7.195z"/>
                <path stroke="#001faf" stroke-width="6" d="M26 96.5h54"/>
            </g>
          </svg>
        </a>
        <p class="headline">Grafton Place Website Terms of Use</p>

        <p class="headline">What is this document and why should you read it?</p>
        <p>1. This privacy notice explains how and why Kells ICAV, 71 Upper Leeson Street, Dublin (also referred to as “we”, “our” and “us”) uses personal data about the users (referred to as “you”) of our website <a href="http://www.graftonplace.com">www.graftonplace.com/</a> (the “Website”).</p>
        <p>2. You should read this notice, so that you know what we are doing with your personal data when you use the Website. Please also read any other privacy notices that we give you, that might apply to our use of your personal data in specific circumstances in the future.</p>

        <p class="headline">Our data protection responsibilities</p>
        <p>1. “Personal data” is any information that relates to an identifiable natural person. Your name, address, contact details, online browsing behaviour are all examples of your personal data, if they identify you.</p>
        <p>2. The term “process” means any activity relating to personal data, including, by way of example, collection, storage, use, consultation and transmission.</p>
        <p>3. We are a “controller” of your personal data. This is a legal term – it means that we make decisions about how and why we process your personal data and, because of this, we are responsible for making sure it is used in accordance with data protection laws.</p>


        <p class="headline">What types of personal data do we collect when you use the Website and where do we get it from?</p>
        <p>1. We collect a limited amount of personal data about you when you use the Website, as set out below. We get the personal data solely from your use of the Website, rather than from third parties who have collected it entirely separately, although we do use third parties to provide services to us in relation to our website and in this way they are relevant to collection of your personal data.</p>
        <p>2. The personal data we collect when you use the Website does not identify you by name but it does identify you because of what are called ‘online identifiers’. This personal data is:</p>
          <ul>
            <li>the IP address (Internet Protocol address) of the device you use to visit the Website;</li>
            <li>information about the web browser (type of browser and version) you use when visiting the Website;</li>
            <li>the pages which you view when you visit the Website during a browsing session; and</li>
            <li>information gathered by certain cookies from which you can be identified because of the IP address which relates to your device (please see paragraph 5 for more details on cookies).</li>
          </ul>
        <p>3. We are a “controller” of your personal data. This is a legal term – it means that we make decisions about how and why we process your personal data and, because of this, we are responsible for making sure it is used in accordance with data protection laws.</p>

        <p class="headline">What do we do with your personal data, and why?</p>
        <p>1. We are required by law to always have a permitted reason or justification (called a “lawful basis”) for processing your personal data. The lawful basis which underpins our processing of your personal data (the ‘online identifiers’) is as follows:</p>
          <ul>
            <li>Lawful reason for processing your IP address, your browser type/version, and cookies which are necessary for the functionality of the Website or which are otherwise categorised as essential for the Website to operate and which link to your IP address: it is necessary for us to process this personal data for the purposes of our ‘legitimate interests’, which are not overridden by your interests, fundamental rights or freedoms. Our legitimate interests in respect of our processing of this personal data are that, for the Website to function properly when you visit it, these cookies are necessary. We have a legitimate interest in enabling that proper functioning.</li>
            <li>Lawful reason for processing cookies which link to your IP address and which are non-essential for the functionality of the Website (ie it would still work without these cookies) , including those which involve processing a record of the pages you view on the Website and from where you are viewing it: we process this personal data on the basis of your consent. If you choose not to consent to the use of these cookies, the Website may not work as efficiently for your during your visit to the Website.</li>
          </ul>
        <p>2. We may also convert your personal data into statistical or aggregated form to better protect your privacy, or so that you are not identified or identifiable from it. Anonymised data cannot be linked back to you. We may use it to conduct research and analysis, including to produce statistical research and reports. For example, to help us understand how many people visit our Website over a specific time period.</p>
        <p>3. Sometimes we need to disclose your personal data to other people</p>
        <p>4. From time to time we ask third parties to carry out certain business functions for us, such as the administration of the Website and our IT support. These third parties will process your personal data on our behalf (as what is called our ‘data processor’). We will disclose your personal data to these parties so that they can perform those functions. Before we disclose your personal data to other people, we will make sure that they have appropriate security standards in place to make sure your personal data is protected and we will enter into a written contract imposing appropriate security standards on them. Examples of these third party service providers include: (i) companies who help us to gather information about the pages most frequently visited on our website and who provide tools for website owners to get a better understanding of users interact with those websites and which help the website owner make their website better; (ii) IT hosting and IT service providers.</p>
        <p>5. In certain circumstances, we will also disclose your personal data to third parties who will receive it as controllers of your personal data in their own right for the purposes set out above, in particular:</p>
          <ul>
            <li>if we transfer, purchase, reorganise, merge or sell any part of our business or the business of a third party, and we disclose or transfer your personal data to the prospective seller, buyer or other third party involved in a business transfer, reorganisation or merger arrangement (and their advisors); and</li>
            <li>if we need to disclose your personal data in order to comply with a legal obligation, to enforce a contract or to protect the rights, property or safety of our employees, customers or others.</li>
          </ul>

        <p class="headline">Cookies</p>
        <p>1.When you visit the Website, we generate one or more “cookies” which we deploy with your consent (if required). Please see paragraph 5.9 for what a “cookie” is.</p>
        <p>2. Not all cookies require consent to be placed on your device. Essential cookies do not require consent. Non-essential cookies do require consent. This is governed by eprivacy law.</p>
        <p>3. Some cookies collect personal data. This is governed by data protection law. This means a lawful reason is needed for us to collect that personal data (by the cookie). Either this is legitimate interests or it is consent. More details are at paragraphs 4.1.1 and 4.1.2.</p>
        <p>4. For cookies where we require consent (whether that requirement comes from eprivacy law or data protection law) we ask for this consent by a consent capture mechanism included in the banner you see when you first visit our Website. You are asked to take a positive action (such as click to give consent) if you do wish to give your consent.</p>
        <p>5. All cookies can be removed from your device at any time. To do this, you should follow the instructions on our cookies banner, set your browser settings accordingly or not use the Website. Please be aware that if you disable the cookies that we use, this may impact your user experience while using the Website.</p>
        <p>6. If you give consent to cookies, then wish to withdraw that consent, you can do this in the same way as described at paragraph 5.5. In addition, see paragraph 9.2 below which is relevant where a cookie collects your personal data.</p>
        <p>7. Two types of cookies may be used on the Website – “session cookies” and “persistent cookies”. Session cookies are temporary cookies that remain on your device until you leave the Website. A persistent cookie remains on your device for much longer or until you manually delete it (how long the cookie remains on your device will depend on the duration or “lifetime” of the specific cookie and your browser settings).</p>
        <p>8. Please note that we do not currently use any targeting/advertising cookies.</p>
        <p>9. What are cookies?</p>
          <ul>
            <li>Cookies are files or pieces of information that may be stored on your computer (or other internet-enabled devices, such as a smartphone or tablet) when you visit the Website. A cookie will usually contain the name of the website from which the cookie has come from, the “lifetime” of the cookie (i.e. how long it will remain on your device) and a value, which is usually a randomly generated unique number.</li>
            <li>Most internet browsers are initially set up to automatically accept cookies. You can change the settings to block cookies or to alert you when cookies are being sent to your device. There are a number of ways to manage cookies. Please refer to your browser instructions or help screen to learn more about how to adjust or modify your browser.</li>
            <li>If you disable the cookies that we use, this may impact your experience while on the Website. For example, you may not be able to visit certain areas of the Website.</li>
            <li>If you use different devices to view and access the Website (e.g. your computer, smartphone, tablet etc.), you will need to ensure that each browser on each device is adjusted to suit your cookie preferences.</li>
          </ul>

        <p class="headline">Where in the world is your personal data transferred to?</p>
        <p>1. If any of our processing activities require your personal data to be transferred outside the European Economic Area, we will only make that transfer if:</p>
          <ul>
            <li>the country to which the personal data is to be transferred ensures an adequate level of protection for personal data;</li>
            <li>we have put in place appropriate safeguards to protect your personal data, such as an appropriate contract with the recipient. (Please contact us as described below in this privacy notice if you wish to obtain a copy of these.);</li>
            <li>the transfer is necessary for one of the reasons specified in data protection legislation, such as the performance of a contract between us and you; or</li>
            <li>you explicitly consent to the transfer.</li>
          </ul>

        <p class="headline">How do we keep your personal data secure?</p>
        <p>1. We will take specific steps (as required by applicable data protection laws) to protect your personal data from unlawful or unauthorised processing and accidental loss, destruction or damage.</p>

        <p class="headline">How long do we keep your personal data for?</p>
        <p>1. We will only retain your personal data for a limited period of time. How long we retain it for depends on the basis on which it was provided and what we use it for. We apply the following criteria to determine how long we retain it:</p>
          <ul>
            <li>where our processing is based on your consent (see paragraph 4.1.2), and you have given us such consent, we will not retain the relevant personal data if you later withdraw such consent (unless there is another lawful basis for us to retain your personal data) or, if earlier, when the purposes for our processing of that personal data have been fulfilled; and</li>
            <li>where our processing is based on our legitimate interests (see paragraph 4.1.1), we will only retain the relevant personal data for so long as necessary for us to pursue those legitimate interests or, where the information retained relates to our use of cookies, for the periods set out in paragraph 5.8.</li>
          </ul>

        <p class="headline">What are your rights in relation to your personal data and how can you exercise them?</p>
        <p>1. You have certain legal rights in relation to any personal data about you which we hold. They do not apply in all circumstances. If you wish to exercise any of them we will explain at that time if they are engaged or not. The rights are:
          <ul>
            <li>the right to be informed about the processing of your personal data;</li>
            <li>the right to have your personal data corrected if it is inaccurate and to have incomplete personal data completed;</li>
            <li>the right to object to processing of your personal data;</li>
            <li>the right to restrict processing of your personal data;</li>
            <li>the right to have your personal data erased (the “right to be forgotten”);</li>
            <li>the right to request access to your personal data and to obtain information about how we process it;</li>
            <li>the right to move, copy or transfer your personal data (“data portability”).</li>
          </ul>
        <p>2. Where our processing of your personal data is based on your consent, you have the right to withdraw your consent at any time. If you do decide to withdraw your consent we will stop processing your personal data for that purpose, unless there is another lawful basis we can rely on – in which case, we will let you know. Your withdrawal of your consent won’t impact any of our processing up to that point.</p>
        <p>3. Where our processing of your personal data is necessary for our legitimate interests, you can object to this processing at any time. If you do this, we will need to show either a compelling reason why our processing should continue, which overrides your interests, rights and freedoms or that the processing is necessary for us to establish, exercise or defend a legal claim.</p>
        <p>4. If you wish to exercise any of your rights please write to us at Kells ICAV, 71 Upper Leeson Street, Dublin.</p>
        <p>5. You also have the right to lodge a complaint with the Information Commissioner’s Office, which is the UK data protection regulator. More information can be found on the Information Commissioner’s Office website at https://ico.org.uk/.</p>

        <p class="headline">Updates to this notice</p>
        <p>1. We may update this notice from time to time to reflect changes to the type of personal data that we process and/or the way in which it is processed. We will update you on material changes to this notice by informing you of that on a banner which will show when you visit the Website for the first time after the update. We also encourage you to check this notice on a regular basis.</p>

        <p class="headline">Where can you find out more?</p>
        <p>1. If you want more information about any of the subjects covered in this privacy notice, or if you would like to discuss any issues or concerns with us, please write to us at Kells ICAV, 71 Upper Leeson Street, Dublin.</p>
      </div>
  `

  document.querySelector('body').innerHTML = termsHTML;
}
