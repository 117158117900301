import './styles/app.styl'
import './assets/fonts/*.otf'
import './scripts/video.js'
import './styles/pages.styl'
import './scripts/cookies.js'
import { render } from './pages/legal'

// Simple/Hacky Routing
if (window.location.pathname == '/legal') {
  render();
} else {
  history.pushState(null, null, '/');
}

window.onpopstate = function(event) {
  location.reload();
};
